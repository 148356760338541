import React from "react"
import styled from "styled-components"

import Layout from "../../layout/layout"
import Footer from "../../components/Footer"
import Navigation from "../../components/Navigation"
import Contact from "../../components/Contact"

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
`
const ContactWrapper = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex: 1;
  padding: 20px 0;

  ${({ theme }) => theme.mq.md} {
    width: 90%;
    padding: 0;
  }

  ${({ theme }) => theme.mq.lg} {
    width: 100%;
  }
`

const index = () => {
  return (
    <Layout>
      <Wrapper>
        <Navigation />

        <ContactWrapper>
          <Contact />
        </ContactWrapper>
        
        <Footer />
      </Wrapper>
    </Layout>
  )
}

export default index
