import React from "react"
import styled from "styled-components"
import { useForm } from "react-hook-form"
import emailjs from "emailjs-com"

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.xxs};

  & > label > ::placeholder,
  & > div > label > ::placeholder {
    color: #d0d5d5;
    font-size: ${({ theme }) => theme.font.size.xxs};
  }

  ${({ theme }) => theme.mq.sm} {
    font-size: ${({ theme }) => theme.font.size.xs};
    & > label > ::placeholder,
    & > div > label > ::placeholder {
      font-size: ${({ theme }) => theme.font.size.xs};
    }
  }

  & > label {
    width: 100%;
    align-self: center;

    &:nth-child(4) {
      max-width: 220px;
      max-height: 60px;
    }

    ${({ theme }) => theme.mq.sm} {
      &:nth-child(3) {
        margin-top: 25px;
        height: 100%;
      }
    }

    ${({ theme }) => theme.mq.md} {
      &:nth-child(3) {
        margin-top: 40px;
      }
    }
  }
`

const TextArea = styled.textarea`
  display: flex;
  border: 0.5px solid ${({ theme }) => theme.colors.grayishCyan};
  outline: none;
  border-radius: 10px;
  padding: 8px 5px;
  resize: none;
  height: 80px;
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.xxs};

  &:focus {
    border: 0.5px solid ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mq.sm} {
    padding: 12px 8px;
    margin-bottom: 25px;
    height: 120px;
    font-size: ${({ theme }) => theme.font.size.xs};
  }
  ${({ theme }) => theme.mq.md} {
    margin-bottom: 40px;
    height: 180px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 15px;
    height: 240px;
  }
  ${({ theme }) => theme.mq.xlg} {
    padding: 20px;
    height: 300px;
  }
`

const Input = styled.input`
  display: flex;
  border: 0.5px solid ${({ theme }) => theme.colors.grayishCyan};
  outline: none;
  border-radius: 10px;
  padding: 8px 5px;
  width: 100%;
  max-height: 60px;
  margin: 5px 0px;

  &:focus {
    border: 0.5px solid ${({ theme }) => theme.colors.black};
  }

  ${({ theme }) => theme.mq.sm} {
    margin: 0;
    padding: 12px 5px;
  }
  ${({ theme }) => theme.mq.md} {
    height: 100%;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 15px 10px;
  }
  ${({ theme }) => theme.mq.xlg} {
    padding: 20px;
  }
`

const DataInputsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.sm} {
    flex-direction: row;
    max-height: 60px;
    height: 100%;

    & > label {
      width: 100%;

      &:nth-child(1) {
        margin-right: 10px;
      }
      &:nth-child(2) {
        margin-left: 10px;
      }
    }
  }

  ${({ theme }) => theme.mq.md} {
    & > label {
      &:nth-child(1) {
        margin-right: 15px;
      }
      &:nth-child(2) {
        margin-left: 15px;
      }
    }
  }
`

const SubmitBtn = styled.button`
  display: block;
  color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: 100px;
  cursor: pointer;
  border: 1px solid transparent;
  background: ${({ theme }) => theme.gradient.defaultBlueGradient};
  border-radius: 10px;
  text-transform: uppercase;
  padding: 8px 0px;
  margin: 0 auto;
  margin-top: 20px;
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.xxs};
  transition: all 0.4s ease-in-out;
  outline: none;

  ${({ theme }) => theme.mq.sm} {
    padding: 10px 0px;
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-top: 30px;
    max-width: 220px;
  }

  ${({ theme }) => theme.mq.lg} {
    padding: 18px 0px;
    margin-top: 40px;
  }

  &:hover,
  &:focus {
    background: ${({ theme }) => theme.colors.white};
    color: #2178ff;
    border: 1px solid #2178ff;
    text-decoration: underline;
  }
`

const ErrorInformation = styled.span`
  color: red;
  font-size: 12px;
  width: fit-content;
  margin: 0 auto;
`

const defaultValues = {
  name: null,
  email: null,
  subject: null,
  message: null,
}

const CHECK_IF_EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i

const ContactForm = () => {
  const { register, handleSubmit, errors, reset } = useForm({
    defaultValues,
  })

  const sendEmail = handleSubmit(data => {
    emailjs.send(
      "service_8pk2tb7",
      "template_xrj41sb",
      data,
      "user_bumG7Rv7c8QhDRuiEqCK6"
    )
    reset()
  })

  return (
    <Form onSubmit={sendEmail}>
      <label htmlFor="message">
        <TextArea
          id="message"
          placeholder="Wiadomość"
          name="message"
          ref={register({ required: true })}
        ></TextArea>
      </label>

      <DataInputsWrapper>
        <label htmlFor="name">
          <Input
            id="name"
            type="text"
            placeholder="Imię *"
            name="name"
            ref={register({ required: true })}
          />
        </label>
        <label htmlFor="email">
          <Input
            type="email"
            id="email"
            placeholder="E-mail *"
            name="email"
            ref={register({
              required: true,
              pattern: {
                value: CHECK_IF_EMAIL_REGEX,
              },
            })}
          />
        </label>
      </DataInputsWrapper>

      <label htmlFor="topic">
        <Input
          type="text"
          id="topic"
          placeholder="Temat"
          name="subject"
          ref={register({ required: true })}
        />
      </label>

      {(errors.message || errors.name || errors.email || errors.subject) && (
        <ErrorInformation>
          Wypełnij wszystkie pola oraz poprawny adres e-mail
        </ErrorInformation>
      )}

      <label htmlFor="submitBtn">
        <SubmitBtn id="submitBtn" type="submit">
          Wyślij
        </SubmitBtn>
      </label>
    </Form>
  )
}

export default ContactForm
