import React from "react"
import styled from "styled-components"
import ContactForm from "../ContactForm"

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1150px;
  height: 100%;
  width: 90%;
  flex: 1;
  padding-bottom: 50px;

  ${({ theme }) => theme.mq.md} {
    width: 80%;
  }

  ${({ theme }) => theme.mq.lg} {
    width: 100%;
  }
`
const Heading = styled.h1`
  font-weight: ${({ theme }) => theme.font.weight.semibold};
  font-size: ${({ theme }) => theme.font.size.md};

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.xlg};
  }

  ${({ theme }) => theme.mq.lg} {
    font-size: 42px;
  }
`

const SubHeading = styled.h2`
  font-weight: ${({ theme }) => theme.font.weight.regular};
  font-size: ${({ theme }) => theme.font.size.xxs};
  color: ${({ theme }) => theme.colors.grayishCyan};
  margin-bottom: 30px;
  text-align: center;

  ${({ theme }) => theme.mq.md} {
    font-size: ${({ theme }) => theme.font.size.xs};
    margin-bottom: 50px;
  }
`

const Contact = () => {
  return (
    <Wrapper>
      <Heading>Kontakt</Heading>
      <SubHeading>Masz pytania? Skontaktuj się ze mną.</SubHeading>
      <ContactForm />
    </Wrapper>
  )
}

export default Contact
